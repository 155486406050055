import * as types from "../constants";

export function toggleSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_TOGGLE
  };
}

export function logout() {
  var admin_id= localStorage.getItem('uname');
  // alert(admin_id);
    localStorage.clear();
    // if(admin_id != null){
    //   window.location.href = '/';
    // }

   // this.props.history.push('/');
 
}


export function showSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_SHOW
  };
}

export function hideSidebar() {
  return {
    type: types.SIDEBAR_VISIBILITY_HIDE
  };
}

export function enableRightSidebar() {
  return {
    type: types.SIDEBAR_RIGHT_ENABLE
  };
}

export function disableRightSidebar() {
  return {
    type: types.SIDEBAR_RIGHT_DISABLE
  };
}
